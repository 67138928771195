import React from "react";
import ViewResume from "../components/viewResume";

export default function Resume() {
  return (
    <div>
      <h1 className={ `header` }>Resume</h1>
      <ViewResume
        buttonProps={{ 
          size: "large" 
        }}
        iconContextProviderProps={{
          value: { 
            color: "white", 
            size: "20px", 
            style: { margin: "0px 0px 0px 4px" }
          }
        }}
      />
    </div>
  );
};
