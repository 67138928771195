import React from "react";

import { IconContext } from "@react-icons/all-files";
import Button from "@material-ui/core/Button";
import { HiOutlineExternalLink } from "@react-icons/all-files/hi/HiOutlineExternalLink";

export default function ViewResume({
    buttonProps = {},
    iconContextProviderProps = {},
    label = "View Resume"
}) {
  const labelId = label.toLowerCase().replace(/\s/gi, '-');

  function viewResume() {
    const fileName = "AmySitwalaResume.pdf";
    const link = document.getElementById(labelId);
    link.setAttribute("href", `${process.env.GATSBY_ASSETS_URL}/${fileName}`);
    link.setAttribute("target", "_blank");
    link.click();
  }

  return (
    <div>
      <Button variant="contained" color="primary" disableElevation onClick={() => viewResume()} {...buttonProps} >
        {label}
        <IconContext.Provider {...iconContextProviderProps} >
          <HiOutlineExternalLink />
        </IconContext.Provider>
      </Button>
      <a href="/" id={labelId} aria-label={label} > </a>
    </div>
  );
};
